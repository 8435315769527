import React, { Fragment, useEffect, useState } from "react";
import { CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { currencySymbol } from "../../api/currencyData";
import { currencyFormatter, handleDate } from "../../api/helper";
import ButtonLoader from '../ButtonLoader';
import { useForm } from "react-hook-form";

const VendorModal = ({ value, setOpenModal, preData, action, projectId, setUpdate }) => {
    const [open, setOpen] = useState(value);
    const [countryList, setCountryList] = useState([]);
    const [state, setState] = useState(preData?.state);
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, resetField } = useForm({ defaultValues: preData });
    const [description, setDescription] = useState(preData?.description);

    const getCountry = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                setCountryList(res.json)
            }, (err) => console.log(err))
    }

    const handleCountry = async (id) => {
        const selectedCountry = countryList?.find(ele => ele.id === id);
        setCountry(id)
        await fetchWrapper(`${API_URL}/state/country/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    setStateList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        // getCountry();
    }, [])

    const onClose = () => {
        setOpenModal(false);
        setOpen(false);
    }

    const onSubmit = async (data) => {
        console.log(data)
        setLoading(true)
        let url, message;
        data.projectId = projectId;
        data.email = data.email ? data.email : null;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/vendor`
            message = 'Vendor Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/vendor/${data.id}`
            message = 'Vendor Updated Successfully!'
        }
        const options = {
            method: method,
            body: data
        }
        await fetchWrapper(url, options)
            .then((res) => {
                console.log(res)
                if ([200, 201]?.includes(res.status)) {
                    setLoading(false)
                    toast.success(message)
                    setUpdate(true)
                    onClose()
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                onClose()
                if (err.status === 409) {
                    toast.error(err.json?.ErrorMessage)
                } else if (err.status === 201) {
                    toast.success(message)
                } else {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Vendor Details</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg="6">
                                <FormGroup >
                                    <Label>Vendor Name <span className='required'>*</span> </Label>
                                    <input
                                        className="form-control"
                                        name="name"
                                        placeholder=""
                                        type="text"
                                        defaultValue={preData?.name}
                                        {...register('name', { required: true })}
                                    />
                                    <span className="text-danger">{errors.name && 'Vendor Name is Required'}</span>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup >
                                    <Label> Email ID</Label>
                                    <input
                                        className="form-control"
                                        name="email"
                                        placeholder=""
                                        type="email"
                                        defaultValue={preData?.email || ''}
                                        {...register('email', { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                    />
                                    <span className="text-danger">{errors?.email?.type === 'required' && 'email is Required'}</span>
                                    <span className="text-danger">{errors?.email?.type === 'pattern' && 'Invalid Email'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                <FormGroup>
                                    <Label>Description</Label>
                                    <textarea className="form-control" rows={3} 
                                    {...register('description')} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="d-flex " style={{ marginTop: "25px" }}>
                            {
                                loading ? <ButtonLoader loaderText={`${action === "Add" ? action : 'Updat'}ing`} color="primary" /> : <Button color="primary" >{action === "Add" ? action : 'Update'}</Button>
                            }
                            <Button color="secondary" className="ml-1" onClick={() => onClose()}> Cancel</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default VendorModal;