import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { defaultUserType, defaultMonitoringColumnName } from '../../api/helper';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { GrFormUpload } from 'react-icons/gr';
import ButtonLoader from '../ButtonLoader';
import MonthlyMonitoring from '../MonthlyMonitoring';
import PlasticMonitoring from '../PlasticMonitoring';
import { PROJECT_DETAILS } from '../../Constant';

const Monitoring = () => {
    const projectId = useParams()?.id;
    const projectName = useParams()?.name;
    const userType = useSelector(state => state.user?.userType);
    const dispatch = useDispatch();
    const type = useParams()?.type;
    const frequency = useParams()?.frequency;
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const navigate = useNavigate();
    const [vendorName, setVendorName] = useState();
    const [vendorList, setVendorList] = useState([]);

    const getAllVendors = async () => {
        await fetchWrapper(`${API_URL}/vendor/project/${projectId}`)
            .then((res) => {
                console.log(res.json)
                setVendorList(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    const projectType = {
        'plastic': 'Plastic Waste Collection (Metric tons)',
        'bms': 'Energy Consumption',
        'solar': 'Energy Generation',
        'lighting': 'Energy Consumption'
    }


    const navigateUpload = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/monitoring-document-upload/${projectId}/${projectName}`);
    }

    const loadProjectData = async () => {
        await fetchWrapper(`${API_URL}/projects/${projectId}`)
            .then((res) => {
                dispatch({
                    type: PROJECT_DETAILS,
                    payload: res.json
                })
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (!frequency) {
            loadProjectData()
        }
        getAllVendors()
    }, [])

    return (
        <Fragment>
            <Breadcrumbs parent="EV Charges List" title="List" mainTitle={`${projectName}`} />
            <Col lg={12}>
                <Card>
                    <CardBody>
                        {
                            <div className="d-flex">
                                <H3 attrH3={{ className: 'title-color' }}>{projectType[type]}</H3>
                                <Button color="primary" className="ml-1  right-1" onClick={navigateUpload}>Upload</Button>
                            </div>
                        }

                        <div className="d-flex">
                            <Row>
                                <Col lg={(frequency === 'DAILY') ? '3' : '4'}>
                                    <FormGroup>
                                        <Label>Data Collection Frequency</Label>
                                        <select className='form-select' value={frequency}
                                            disabled={true}
                                            {...register('reportingPeriodFrequency', { required: true })}
                                        >
                                            <option value=''>Select Period</option>
                                            <option value={'DAILY'}>Daily</option>
                                            <option value={'MONTHLY'}>Month</option>
                                            <option value={'QUARTERLY'}>Quarter</option>
                                            <option value={'HALFYEARLY'}>Semi Annual</option>
                                            <option value={'YEARLY'}>Annual</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg={(frequency === 'DAILY') ? '3' : '4'}>
                                    <FormGroup>
                                        <Label>Select Year</Label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={year}
                                            onChange={(e) => setYear(e)}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            maxDate={new Date()}
                                        />
                                    </FormGroup>
                                </Col>
                                {
                                    (frequency === 'DAILY') && <Col lg='3'>
                                        <FormGroup>
                                            <Label>Select Month</Label>
                                            <DatePicker
                                                className="datepicker-here form-control"
                                                selected={month ? month : new Date()}
                                                onChange={(e) => setMonth(e)}
                                                showMonthYearPicker
                                                dateFormat="MMM"
                                                placeholderText="-Month-"
                                                maxDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                <Col lg={(frequency === 'DAILY') ? '3' : '4'}>
                                    <FormGroup>
                                        <Label>Vendor</Label>
                                        <select className='form-select' name='vendor' value={vendorName}
                                            onChange={(e) => setVendorName(e.target.value)}>
                                            <option value={''}>-Select vendor-</option>
                                            {
                                                vendorList?.filter(ele => ele?.status === 'ACTIVE')?.map((item) => (
                                                    <option value={item?.id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>

                        {
                            (type === 'solar') && <MonthlyMonitoring projectId={projectId}
                                frequency={'MONTHLY'} year={year} userType={userType} columnType={defaultMonitoringColumnName[type]}
                                projectName={projectName} type={type} />
                        }
                        {
                            (type === 'plastic') && <PlasticMonitoring projectId={projectId} vendor={vendorName}
                                frequency={frequency} year={year} month={month} userType={userType} projectName={projectName} type={type} />
                        }
                    </CardBody>
                </Card>
            </Col>

        </Fragment>
    )
}

export default Monitoring;