import React, { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Row, Col, Card, CardBody, Label, Button, InputGroup, InputGroupText } from 'reactstrap';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { H3, H4, H5 } from '../../../AbstractElements';
import { defaultHeader, replaceYear, isNumber, totalScopes, totalScopesDescription } from './TargetData';
import SummaryView from './SummaryView';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import DataSheet from './ReactDataSheet';
import { convertTimeformat, thousandSeparator } from '../../api/helper';
import ButtonLoader from '../ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import { USER_DETAILS } from '../../Constant';
import Swal from 'sweetalert2';
import NoDataComponent from './NoDataComponent';

const TargetSettingView = () => {
    const [baseYear, setbaseYear] = useState();
    const [targetYear, setTargetYear] = useState();
    const [savedTargetYear, setTarget] = useState();
    const [savedBaseYear, setSavedBaseYear] = useState();
    const [open, setOpen] = useState(false);
    const [scopeCategory, setScopeCategory] = useState({});
    const [scope1Data, setScope1Data] = useState([]);
    const [scope2Data, setScope2Data] = useState([]);
    const [scope3Data, setScope3Data] = useState([]);
    const [baseYearData, setBaseYearData] = useState();
    const [offset, setOffset] = useState([]);
    const [summary, setSummary] = useState([]);
    const [calculatedTarget, setCalculatedTarget] = useState(0);
    const [isUpdateGrid, setUpdateGrid] = useState(false);
    const [locationBased, setLocationBased] = useState(true);
    const [locationCategory, setLocationCategory] = useState([]);
    const [marketCategory, setMarketCategory] = useState([])
    let julyFirst = new Date(new Date().getFullYear(), 6, 1);
    const [baseYearList, setBaseYearList] = useState();
    const [startLoading, setStartLoading] = useState(false);
    const [count, setCount] = useState(11);
    const [targetStatus, setTargetStatus] = useState(false);
    const [ghgStatus, setGhgStatus] = useState(true);
    const [actionData, setActionData] = useState([]);
    const [orgScopes, setOrgScopes] = useState([])

    let status = false;
    const dispatch = useDispatch();

    const gridRef = useRef(null);

    const dataExists = (year, category) => {
        const existData = actionData.find((ele) => (ele?.year === year && ele?.category === category))
        return existData ? existData?.value : false
    }

    const loadBaseYearData = (scopeData, responseData, baseYear, readStatus) => {
        let readOnly = sessionStorage.getItem('userType') === 'ASO_ADMIN' ? readStatus : true;
        const responseArray = Object.values(responseData);
        const actualDataYear = Object.keys(responseData);
        for (let i = 0; i < scopeData?.length; i++) {
            for (let j = 0; j < scopeData[0]?.length - 1; j++) {
                responseArray?.map((tracking) => {
                    tracking?.map((ele) => {
                        if (i > 0) {
                            if (ele?.itemKey === scopeData[i][0]?.value) {
                                if ((Number(ele?.year) === scopeData[0][j + 1]?.value) && (scopeData[0][j + 1]?.value === Number(baseYear))) {
                                    scopeData[i][j] = {
                                        value: ele?.value ? Number(ele?.value).toFixed(2) : 0,
                                        width: 100,
                                        className: `text-right-imp min-w-80`,
                                        readOnly: true,
                                        actualData: true,
                                        valueUpdated: false
                                    }
                                }
                                if (j > 0) {
                                    if ((scopeData[0][j + 1]?.value !== Number(baseYear))
                                        && (Number(ele?.year) === scopeData[0][j + 1]?.value) && ele?.actualData === 'True') {
                                        scopeData[i][j] = {
                                            value: Number(ele?.value).toFixed(2),
                                            width: 100,
                                            className: `text-right-imp min-w-80`,
                                            readOnly: ((ele?.actualData === 'True' || !targetYear || sessionStorage.getItem('userType') === 'ASO_USER')) ? true : false,
                                            actualData: (ele?.actualData === 'True' || !targetYear) ? true : false,
                                            hiddenValue: Number(ele?.value).toFixed(2),
                                            valueUpdated: false
                                        }
                                    } else if (targetYear) {
                                        if ((readStatus === true ? !scopeData[i][j]?.value : scopeData[i][j]?.value) &&
                                            scopeData[i][j]?.actualData !== true) {
                                            scopeData[i][j] = {
                                                value: dataExists(scopeData[0][j + 1]?.value, scopeData[i][0]?.value) === false ? Number(scopeData[i][j - 1]?.value).toFixed(2) : Number(dataExists(scopeData[0][j + 1]?.value, scopeData[i][0]?.value)).toFixed(2),
                                                width: 100,
                                                className: `text-right-imp min-w-80`,
                                                readOnly: readOnly,
                                                valueUpdated: false,
                                                hiddenValue: dataExists(scopeData[0][j + 1]?.value, scopeData[i][0]?.value) === false ? Number(scopeData[i][j - 1]?.value).toFixed(2) : dataExists(scopeData[0][j + 1]?.value, scopeData[i][0]?.value),
                                            }
                                        }
                                        if ((readStatus === false) && !scopeData[i][j]?.value) {
                                            scopeData[i][j] = {
                                                value: !scopeData[i][j]?.value ? Number(scopeData[i][j - 1]?.value).toFixed(2) :
                                                    Number(scopeData[i][j]?.value).toFixed(2),
                                                width: 100,
                                                className: `text-right-imp min-w-80`,
                                                readOnly: readOnly,
                                                valueUpdated: false,
                                                hiddenValue: !scopeData[i][j]?.value ? Number(scopeData[i][j - 1]?.value).toFixed(2) :
                                                    Number(scopeData[i][j]?.value).toFixed(2)
                                            }
                                        }

                                    }
                                }
                            }
                            if (targetYear && (Number(scopeData[0][j]?.value) >= moment(targetYear).format('YYYY'))) {
                                scopeData[i][j] = {
                                    value: '',
                                    width: 100,
                                    className: `text-right-imp min-w-80`,
                                    readOnly: true,
                                }
                            }
                            if ((Number(ele?.year) === scopeData[0][j + 1]?.value) && (scopeData[0][j + 1]?.value === Number(baseYear))) {
                                if (j > 0) {
                                    scopeData[i][j] = {
                                        ...scopeData[i][j],
                                        readOnly: true
                                    }
                                }
                            }
                        }
                    })
                })
                if (!responseData) {
                    if (i > 0 && j === 1) {
                        scopeData[i][j] = { ...scopeData[i][j], readOnly: true }
                    }
                    if (i > 0 && j > 1) {
                        scopeData[i][j] = { ...scopeData[i][j], readOnly: readStatus }
                    }
                }
            }
        }
        let total = new Array(scopeData[0]?.length).fill(0);
        for (let i = 1; i < scopeData?.length; i++) {
            for (let j = 1; j < scopeData[0]?.length - 1; j++) {
                if (i < (scopeData?.length - 1)) {
                    total[j] = total[j] + (scopeData[i][j]?.value !== 'NaN' ? Number(scopeData[i][j]?.value) : 0);
                    scopeData[scopeData?.length - 1][j] = {
                        value: Number(total[j]).toFixed(2),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp f-w-600 min-w-80'
                    }
                }
            }
        }
        for (let i = 1; i < scopeData?.length; i++) {
            for (let j = 1; j < scopeData[0]?.length - 1; j++) { 
                if(scopeData[i][j]?.value === 'NaN') {
                    scopeData[i][j] = {
                        value: 0,
                        readOnly: true,
                        width: 100,
                        className: 'text-right-imp'
                    }
                }
            }
        }
        return scopeData;
    }

    const calculateTarget = (newData) => {
        const base = moment(baseYear).format('yyyy');
        const targetYr = targetYear ? moment(targetYear).format('yyyy') : 0;
        const baseTotal = newData[newData?.length - 1][1]?.value;
        let targetTotal = 0;
        for (let j = 1; j < newData[1].length; j++) {
            if (Number(targetYr) === newData[0][j + 1]?.value) {
                targetTotal = newData[newData?.length - 1][j]?.value;
            }
        }
        let calcTarget = (targetYear > 0 && isUpdateGrid) ? ((Number(baseTotal) - Number(targetTotal)) / Number(baseTotal)) * 100 : 0;
        setCalculatedTarget(Number(calcTarget).toFixed(2));
    }

    const loadSummaryData = (scope1Data, scope2Data, scope3Data) => {
        let newData = [...summary];
        newData[1] = [...scope1Data[scope1Data?.length - 1]];
        newData[2] = [...scope2Data[scope2Data?.length - 1]];
        newData[3] = [...scope3Data[scope3Data?.length - 1]];
        newData[5] = [...offset[offset?.length - 1]];
        for (let j = 0; j < newData[0]?.length - 1; j++) {
            newData[1][j] = { ...newData[1][j], className: `text-right-imp f-w-500 min-w-80 ${j === 0 && 'cat-title-label'}` };
            newData[2][j] = { ...newData[2][j], className: `text-right-imp f-w-500 min-w-80 ${j === 0 && 'cat-title-label'}` };
            newData[3][j] = { ...newData[3][j], className: `text-right-imp f-w-500 min-w-80 ${j === 0 && 'cat-title-label'}` };
            newData[5][j] = { ...newData[5][j], className: `text-right-imp f-w-500 min-w-80 ${j === 0 && 'cat-title-label'}` };
        }
        let total = new Array(newData[0]?.length).fill(0);
        for (let i = 1; i <= newData?.length; i++) {
            for (let j = 1; j < newData[0]?.length - 1; j++) {
                if (i < 4) {
                    total[j] = total[j] + Number(newData[i][j]?.value);
                    newData[4][j] = {
                        value: Number(total[j]).toFixed(2),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp f-w-600 min-w-80'
                    }
                }
                if (i === 5) {
                    total[j] = total[j] - Number(newData[i][j]?.value);
                    newData[6][j] = {
                        value: Number(total[j]).toFixed(2),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp f-w-600 min-w-80'
                    }
                }
            }
        }
        calculateTarget(newData);
        setSummary(newData);
        setStartLoading(false)
    }

    const loadEmptyGrid = (scopeData) => {
        for (let i = 1; i < scopeData?.length; i++) {
            for (let j = 1; j < scopeData[0]?.length - 1; j++) {
                scopeData[i][j] = {
                    value: '',
                    width: 100,
                }
            }
        }
        return scopeData;
    }

    const loadOffsetData = (scopeData, responseData, baseYear, readStatus) => {
        for (let i = 0; i < scopeData?.length - 1; i++) {
            for (let j = 0; j < scopeData[0]?.length - 1; j++) {
                if (i > 0 && j > 0) {
                    scopeData[i][j] = {
                        value: '',
                        width: 100,
                        className: `text-right-imp`,
                        actualData: ""
                    }
                }
            }
        }
        const responseArray = Object.values(responseData);
        const tempCategory = [];
        responseArray?.map((item) => {
            item?.map(element => tempCategory.push(element?.itemKey))
        });
        let offsetCategory = [...new Set(tempCategory)];
        for (let i = 1; i < scopeData?.length - 1; i++) {
            scopeData[i][0] = {
                value: offsetCategory[i - 1],
                width: 300,
                className: 'side-menu f-w-600 min-w-200 cat-title-label'
            }
        }
        for (let i = 0; i < scopeData?.length - 1; i++) {
            for (let j = 0; j < scopeData[0]?.length - 1; j++) {
                responseArray?.map((tracking) => {
                    tracking?.map((ele) => {
                        if (i > 0) {
                            if (ele?.itemKey === scopeData[i][0]?.value) {
                                if (ele?.year === scopeData[0][j + 1]?.value) {
                                    scopeData[i][j] = {
                                        value: Number(ele?.value).toFixed(2),
                                        width: 100,
                                        className: `text-right-imp min-w-80`,
                                        actualData: "False"
                                    }
                                }
                            }

                        }
                    })
                })
            }
        }
        let total = new Array(scopeData[0]?.length).fill(0);
        for (let i = 1; i < scopeData?.length; i++) {
            for (let j = 1; j < scopeData[0]?.length - 1; j++) {
                if (i < (scopeData?.length - 1)) {
                    total[j] = total[j] + Number(scopeData[i][j]?.value);
                    scopeData[scopeData?.length - 1][j] = {
                        value: Number(total[j]).toFixed(2),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp f-w-600 min-w-80'
                    }
                }
            }
        }
        return scopeData;
    }

    const handleLoadGrid = async (json, year) => {
        if (json) {
            await setScope1Data(loadBaseYearData(scope1Data, json['Scope 1 Emissions'], year, targetYear ? false : true));
            await setScope2Data(loadBaseYearData(scope2Data, json['Scope 2 Emissions'], year, targetYear ? false : true));
            await setScope3Data(loadBaseYearData(scope3Data, json['Scope 3 Emissions'], year, targetYear ? false : true));
            json['Offset'] && await setOffset(loadOffsetData(offset, json['Offset'], year, targetYear ? false : true));
            loadSummaryData(scope1Data, scope2Data, scope3Data)
        }
    }

    const getActuals = async (year) => {
        let actualDataArray = [];
        await fetchWrapper(`${API_URL}/targetsettings/actuals/${year}`)
            .then(res => {
                actualDataArray = res.json?.details;
            }, async (err) => {
                console.log(err)
                if (err.status === 404) {
                    Swal.fire("Please Update GHG Emission data..");
                    await setScope1Data(loadEmptyGrid(scope1Data));
                    await setScope2Data(loadEmptyGrid(scope2Data));
                    await setScope3Data(loadEmptyGrid(scope3Data));
                    loadSummaryData(scope1Data, scope2Data, scope3Data);
                }
            })
        return actualDataArray;
    }

    const getTargetData = async () => {
        let targetDataArray = [];
        await fetchWrapper(`${API_URL}/targetsettings/targetdata`)
            .then(res => {
                targetDataArray = res.json?.details;
                if (res.json?.targetYear) {
                    setTargetStatus(true)
                    setTarget(res.json?.targetYear)
                    setUpdateGrid(true)
                    setTargetYear(new Date(convertTimeformat(res.json?.targetYear)))
                }
                if (res.json?.baseYear) {
                    setSavedBaseYear(res.json?.baseYear)
                }
            }, async (err) => {
                if (err?.status === 404) {
                    status = false;
                }
                console.log(err)
            })
        return targetDataArray;
    }

    const mergeOperation = (actual, targets) => {
        let mergedArray;
        if (!targets) {
            mergedArray = { ...actual };
        } else {
            if (Object.keys(actual)?.some(value => Object.keys(targets).includes(value))) {
                mergedArray = { ...targets, ...actual }
            } else {
                mergedArray = { ...actual, ...targets }
            }
        }
        return mergedArray;
    }

    const getTargetDetails = async (year) => {
        const actuals = await getActuals(year)
        const targets = await getTargetData()
        const data = {
            'Scope 1 Emissions': mergeOperation(actuals['Scope 1 Emissions'], targets['Scope 1 Emissions']),
            'Scope 2 Emissions': mergeOperation(actuals['Scope 2 Emissions'], targets['Scope 2 Emissions']),
            'Scope 3 Emissions': mergeOperation(actuals['Scope 3 Emissions'], targets['Scope 3 Emissions']),
            'Offset': targets['Offset']
        }
        setBaseYearData(data);
        handleLoadGrid(data, year);
    }

    const populateBaseYear = async (e, getYear) => {
        setbaseYear(new Date(convertTimeformat(getYear)));
        let columnCount = calculateYearsCount(getYear);
        setScope1Data(replaceYear(scope1Data, getYear, columnCount, 'scope'));
        setScope2Data(replaceYear(scope2Data, getYear, columnCount, 'scope'));
        setScope3Data(replaceYear(scope3Data, getYear, columnCount, 'scope'));
        setOffset(replaceYear(offset, getYear, columnCount, 'offset'));
        setSummary(replaceYear(summary, getYear, columnCount, 'scope'))
        await getTargetDetails(getYear);
    }

    const calculateYearsCount = (year) => {
        let current = moment(new Date()).format('YYYY');
        let limit = new Date() >= julyFirst ? 11 : 10;
        let differ1 = current - year;
        setCount(differ1 + limit);
        return Number(differ1 + limit)
    }

    const handleBaseYear = (e) => {
        setStartLoading(true)
        let getYear = moment(e).format('yyyy');
        calculateYearsCount(getYear)
        populateBaseYear(e, getYear)
    }

    const populateYearRows = () => {
        let tempData = defaultHeader?.map((item, index) => {
            return {
                value: item,
                readOnly: true,
                width: 100,
                className: `menu-style target-header min-w-200 ${index === 0 && 'cat-title-label'}`
            }
        })
        const baseYearValue = typeof baseYear === 'object' ? moment(baseYear).format('yyyy') : baseYear;
        tempData?.map((item, index) => {
            if (index > 1) {
                item.value = Number(baseYearValue) + (index)
            } else {
                item.value = Number(baseYearValue)
            }
        })
        return tempData;
    }

    const loadDefaultHeader = (columnData, scopeLabel, gridData, key) => {
        let getHeaderArray = [], columnArray = [];
        let tempData;
        if (key !== 'offset') {
            const sideColumn = columnData?.length > 0 ? columnData : [];
            for (let i = 0; i < sideColumn?.length; i++) {
                if (i === 0) {
                    tempData = [
                        {
                            value: scopeLabel,
                            rowSpan: sideColumn?.length,
                            readOnly: true,
                            width: 100,
                            className: 'min-w-75 text-center verticle-middle f-w-600 scopeLabel'
                        }
                    ]
                } else {
                    tempData = [
                        {
                            value: sideColumn[i],
                            readOnly: true,
                            width: 300,
                            className: `min-w-200 cat-title-label side-menu  ${(scopeLabel === 'Summary' && i === 4) && 'f-w-600'}`
                        }
                    ]
                }
                columnArray.push(tempData)
            }
        }
        if (baseYear) {
            if (gridData?.length > 0) {
                getHeaderArray = gridData[0]?.filter((i, index) => index > 0)
            } else {
                getHeaderArray = populateYearRows();
            }
        } else {
            getHeaderArray = defaultHeader?.map((item, index) => {
                return {
                    value: item,
                    readOnly: true,
                    width: 100,
                    className: `menu-style target-header f-w-600 min-w-100 ${index === 0 && 'cat-title-label'}`
                }
            })
        }
        if (key !== 'offset') {
            columnArray[0] = columnArray && getHeaderArray && [...columnArray[0], ...getHeaderArray];
            let tempArray = columnArray?.map((ele, index) => {
                if (index > 0) {
                    let emptyArray;
                    emptyArray = key ? new Array(11).fill({
                        value: '',
                        width: 100,
                        readOnly: scopeLabel === 'Summary' ? true : false,
                        className: scopeLabel === 'Summary' && 'text-right-imp cat-title-label'
                    }) :
                        (index === (columnArray?.length - 1))
                            ? new Array(11).fill({ value: '', width: 100, readOnly: true })
                            : new Array(11).fill({ value: '', width: 100 })
                    return [...ele, ...emptyArray]
                } else {
                    return ele
                }
            })
            return [...tempArray];
        } else {
            const offsetHeader = {
                value: scopeLabel,
                rowSpan: 6,
                readOnly: true,
                width: 100,
                className: 'text-center verticle-middle f-w-600 min-w-75 scopeLabel'
            }
            getHeaderArray?.unshift(offsetHeader)
            getHeaderArray[1] = {
                "value": "Description",
                "readOnly": true,
                "width": 300,
                "className": "menu-style target-header f-w-600 min-w-200 cat-title-label"
            }

            const tempArray = Array(getHeaderArray?.length - 1).fill({ value: '' }).map((item, index) => {
                if (index === 0) {
                    return {
                        value: '',
                        readOnly: false,
                        width: 300,
                        className: 'menu-style f-w-600 min-w-200 cat-title-label'
                    }
                } else {
                    return {
                        value: '',
                        readOnly: false,
                        width: 100,
                        className: 'f-w-500'
                    }
                }
            })
            const totalArray = tempArray.map((item, index) => {
                if (index > 0)
                    return {
                        value: '',
                        readOnly: true,
                        width: 100,
                        className: 'menu-style min-w-80',
                    }
                else
                    return {
                        value: 'Total Offsets',
                        readOnly: true,
                        width: 300,
                        className: 'side-menu f-w-600 min-w-200 cat-title-label'
                    }
            })
            return [[...getHeaderArray], [...tempArray], [...tempArray], [...tempArray], [...tempArray], [...totalArray]];
        }
    }

    const getOrgScopes = async () => {
        await fetchWrapper(`${API_URL}/scopecategories/organization`)
            .then((res) => {
                console.log(res?.json)
                setOrgScopes(res?.json?.map(ele => ele?.categoryName))
            }, (err) => console.log(err))
    }

    const handleScopes = (catData, val) => {
        let sData = catData?.filter(ele => orgScopes?.some(scope => ele?.description?.includes(scope)))?.map((i) => i?.description);
        if (val === 2) {
            let locationData = sData?.filter(ele => ele?.startsWith('Location'))
            locationData?.unshift('Category')
            locationData?.push(totalScopes[val])
            setLocationCategory(locationData)
            let marketData = sData?.filter(ele => ele?.startsWith('Market'))
            marketData?.unshift('Category')
            marketData?.push(totalScopes[val])
            setMarketCategory(marketData)
            sData = locationBased === true ? [...locationData] : [...marketData];
        } else {
            sData?.unshift('Category')
            sData?.push(totalScopes[val])
        }
        return sData;
    }

    const getScopes = async () => {
        let scopeData = {}, usScope = {}, scopes = ['scope1', 'scope2', 'scope3'], scopeLabels = ['Scope 1', 'Scope 2', 'Scope 3'];
        await fetchWrapper(`${API_URL}/targetsettings/categories/details`)
            .then(async (res) => {
                let data = res.json;
                scopeData.scope1 = handleScopes(data["Scope 1 Emissions"], 1)
                scopeData.scope2 = handleScopes(data["Scope 2 Emissions"], 2)
                scopeData.scope3 = handleScopes(data["Scope 3 Emissions"], 3)
                setScopeCategory(scopeData)
                await setScope1Data(loadDefaultHeader(handleScopes(data["Scope 1 Emissions"], 1), scopeLabels[0], scope1Data));
                await setScope2Data(loadDefaultHeader(handleScopes(data["Scope 2 Emissions"], 2), scopeLabels[1], scope2Data));
                await setScope3Data(loadDefaultHeader(handleScopes(data["Scope 3 Emissions"], 3), scopeLabels[2], scope3Data));
            }, err => console.log(err))
    }


    const handleTotal = (newData, col, tabData, scopeDesc) => {
        let scopeTot = 0;
        for (let i = 1; i < (scopeDesc?.length - 1); i++) {
            scopeTot = scopeTot + Number(newData[i][col]?.value);
        }
        newData[scopeDesc?.length - 1][col] = {
            ...tabData[scopeDesc.length - 1][col],
            value: Number(scopeTot)?.toFixed(2), className: 'text-right-imp f-w-600'
        }
        return newData;
    }

    const formOffsetGrid = () => {
        const offsetData = loadDefaultHeader([], 'Offset', [], 'offset');
        setOffset(offsetData)
    }

    const formSummaryGrid = () => {
        const summaryGrid = loadDefaultHeader(totalScopesDescription, 'Summary', [], 'summary')
        setSummary(summaryGrid);
    }

    const getAllBaseYears = async () => {
        await fetchWrapper(`${API_URL}/targetsettings`)
            .then((res) => {
                const dates = res.json?.map((ele) => new Date(convertTimeformat((ele?.baseYear))));
                setBaseYearList(dates);
            }, (err) => {
                console.log(err)
                if (err.status === 404) {
                    setGhgStatus(false)
                }
            })
    }

    const getPayloadData = (gridData) => {
        const targetDetails = []
        for (let j = 1; j < gridData[0]?.length; j++) {
            let itemData = {}, targetValues = [];
            itemData.year = gridData[0][j + 1]?.value;
            for (let i = 0; i < gridData?.length; i++) {
                if (i > 0 && i < gridData?.length - 1) {
                    if (gridData[i][j]?.actualData !== true) {
                        let item = {
                            codeDescription: gridData[i][0]?.value,
                            value: parseFloat(gridData[i][j]?.value),
                            actualData: "False",
                            valueUpdated: gridData[i][j]?.valueUpdated,
                            baseValue: parseFloat(gridData[i][j]?.hiddenValue)
                        }
                        targetValues?.push(item)
                    }
                }
            }
            itemData.targetValues = [...targetValues];
            targetDetails?.push(itemData);
        }
        return targetDetails?.filter(ele => (ele?.year <= Number(moment(targetYear)?.format('YYYY')) && ele?.targetValues?.length > 0));
    }

    const getOffsetPayload = (gridData) => {
        const targetDetails = []
        for (let j = 1; j < gridData[0]?.length; j++) {
            let itemData = {}, targetValues = [];
            itemData.year = gridData[0][j + 1]?.value;
            for (let i = 0; i < gridData?.length; i++) {
                if (gridData[i][j]?.valueUpdated === true) {
                    let item = {
                        codeDescription: gridData[i][0]?.value,
                        value: parseFloat(gridData[i][j]?.value),
                        actualData: "True",
                        baseValue: parseFloat(gridData[i][j]?.hiddenValue)
                    }
                    targetValues?.push(item)
                }
            }
            itemData.targetValues = [...targetValues];
            targetDetails?.push(itemData);
        }
        return targetDetails?.filter(ele => ele?.targetValues?.length > 0);
    }

    const onSubmit = async () => {
        setStartLoading(true)
        const bodyData = {
            baseYear: moment(baseYear).format('YYYY'),
            targetYear: moment(targetYear).format('YYYY'),
            reductionPercentage: Number(calculatedTarget),
            categoryType: locationBased === true ? 'LOCATION' : 'MARKET',
            scopeDetails: [
                {
                    scopeTargetDetails: [
                        {
                            scope: 1,
                            targetDetails: getPayloadData(scope1Data)
                        },
                        {
                            scope: 2,
                            targetDetails: getPayloadData(scope2Data)
                        },
                        {
                            scope: 3,
                            targetDetails: getPayloadData(scope3Data)
                        },
                    ]
                }
            ],
            offset: getOffsetPayload(offset)
        };
        console.log(bodyData);
        const options = {
            method: 'POST',
            body: bodyData,
        }
        await fetchWrapper(`${API_URL}/targetsettings`, options)
            .then(async (res) => {
                if (res.status === 200) {
                    toast.success('Target Data saved successfully!')
                    // await loadUserDetails()
                }
            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    setTimeout(() => {
                        setStartLoading(false)
                        Swal.fire({
                            title: 'Almost Done!',
                            text: `Saving target settings is in progress...`,
                            timer: 50000,
                            timerProgressBar: true,
                            showCloseButton: false,
                            showDenyButton: false,
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            }
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log("I was closed by the timer");
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Attention',
                                    text: 'Target setting saved successfully! It will take 8 to 30 mins to reflect the changes on the dashboard.'
                                })
                            }
                        })
                    }, 10000)
                }
            })
    }

    const getActionPlan = async () => {
        await fetchWrapper(`${API_URL}/actionPlan`)
            .then((res) => {
                const data = res.json?.map(item => {
                    return {
                        year: item.year,
                        value: item.updatedValue,
                        category: item.category
                    }
                })
                setActionData(data);
            }, (err) => (console.log(err)))
    }

    const handleLocationToggle = async (val) => {
        let loadedGrid = [];
        await setLocationBased(val === 'location' ? true : false);
        const tempGrid = loadDefaultHeader(val === 'location' ? locationCategory : marketCategory, 'Scope 2', scope2Data);
        if (baseYearData && Object.keys(baseYearData)?.length > 0) {
            loadedGrid = loadBaseYearData(tempGrid, baseYearData['Scope 2 Emissions'], moment(baseYear).format('yyyy'),
                targetYear ? false : true)
        }
        await setScope2Data(loadedGrid?.length > 0 ? loadedGrid : tempGrid);
    }

    const getBasicDetails = useCallback(async () => {
        await getScopes()
        await formOffsetGrid()
        await formSummaryGrid()
        await getAllBaseYears()
        await getActionPlan()
    })
    useEffect(() => {
        getTargetData()
        getOrgScopes()
    }, [])

    const handleTarget = (e) => {
        setTargetYear(e)
        if (moment(e).format('YYYY') == savedTargetYear) {
            setUpdateGrid(true)
        } else {
            setUpdateGrid(false)
        }

    }

    useEffect(() => {
        getBasicDetails()
    }, [orgScopes])

    useEffect(() => {
        if (baseYear) {
            handleLoadGrid(baseYearData, baseYear);
        }
    }, [targetYear])

    useEffect(() => {
        if (scope1Data?.length > 0 && scope2Data?.length > 0 && scope3Data?.length > 0 && offset?.length > 0) {
            loadSummaryData(scope1Data, scope2Data, scope3Data)
        }
    }, [scope1Data, scope2Data, scope3Data, offset])

    useEffect(() => {
        if (savedBaseYear && targetStatus === true) {
            if (scope1Data?.length > 0 && scope2Data?.length > 0 && scope3Data?.length > 0 && offset?.length > 0) {
                setStartLoading(true)
                populateBaseYear(new Date(savedBaseYear?.toString()), savedBaseYear)
            }
        }
    }, [savedBaseYear, targetStatus, baseYearList])

    useEffect(() => {
        getScopes()
    }, [orgScopes])

    return (
        <Fragment>
            <LayoutLoader />
            {open && <SummaryView value={open} setopenModal={setOpen} baseYear={baseYear} />}
            <div className='ptop-2'>
                <H3 attrH3={{ className: 'credits-table' }}>Net Zero Planner (Metric tons/yr)</H3>
            </div>
            {startLoading ? <div className="loading-overlay">Loading....</div> : <></>}
            {
                ghgStatus ? <Card style={{ opacity: startLoading ? '0.2' : '1' }}>
                    <CardBody className='ptop-0'>
                        <div className="target-settings mtop-1 d-flex justify-between">
                            <div className='d-flex '>
                                <h5 className="mb-0 f-15 text-color">
                                    Base Year: </h5>
                                <div className='ml-0-25 w-5-5'>
                                    <DatePicker
                                        className="datepicker-control"
                                        selected={baseYear ? baseYear : ""}
                                        onChange={(e) => handleBaseYear(e)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        includeDates={baseYearList ? baseYearList : []}
                                        maxDate={new Date(moment(new Date()).subtract(1, 'year'))}
                                    />
                                </div>
                            </div>
                            <div className='d-flex '>
                                <h5 className="mb-0 f-15 text-color">
                                    Target Year: </h5>
                                <div className='ml-0-25 w-5-5'>
                                    <DatePicker
                                        className="datepicker-control"
                                        selected={targetYear}
                                        onChange={(e) => handleTarget(e)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        minDate={new Date() >= julyFirst ? new Date(moment(new Date()).add(5, 'years')) : new Date(moment(new Date()).add(4, 'years'))}
                                        maxDate={new Date() >= julyFirst ? new Date(moment(new Date()).add(10, 'years')) : new Date(moment(new Date()).add(9, 'years'))}
                                    />
                                </div>
                            </div>
                            <div className='d-flex'>
                                <h5 className="mb-0 f-15 text-color">Reduction Target:  </h5>
                                <div className="ml-0-25 input-percent w-6">
                                    <InputGroup>
                                        <input
                                            className="datepicker-control w-100 percent-padding"
                                            name="emissionTarget"
                                            placeholder="0"
                                            type="number"
                                            disabled={true}
                                            min={0}
                                            max={100}
                                            value={calculatedTarget}
                                        />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="d-flex ml-1">
                                <h5 className="mb-0 f-15 text-color">Reduction Tracking:  </h5> <h5 className='ml-0-25 mb-0'>Absolute</h5>
                            </div>
                            <div className="d-flex ml-2">
                                <h5 className="mb-0 f-15 text-color">Scope 2</h5>
                                <div className='d-flex ml-1'>
                                    <input className="form-check-input radio_animated" type='radio' value={'location'}
                                        checked={locationBased === true ? true : false}
                                        onChange={(e) => handleLocationToggle('location')}
                                    /> <lable>Location</lable>
                                    <input className="form-check-input radio_animated ml-1" type='radio' value={'market'}
                                        checked={locationBased === false ? true : false}
                                        onChange={(e) => handleLocationToggle('market')}
                                    /> <lable>Market</lable>
                                </div>

                            </div>

                        </div>
                        <div className='mtop-1'>
                            {
                                scopeCategory['scope1']?.length > 2 && <>
                                    <div className='mtop-1'>
                                        <ScopeDataSheet scopeData={scope1Data} scope={scopeCategory['scope1']}
                                            setUpdateGrid={setUpdateGrid} setScope={setScope1Data} ref={gridRef} handleTotal={handleTotal}
                                            type={1} loadSummaryData={loadSummaryData} baseYear={moment(baseYear).format('YYYY')} targetYear={moment(targetYear).format('YYYY')} />
                                    </div>
                                    <div className='line-div-5'></div>
                                </>
                            }
                            {
                                scopeCategory['scope2']?.length > 2 && <>
                                    <div className='mtop-4px'>
                                        <ScopeDataSheet scopeData={scope2Data} scope={scopeCategory['scope2']}
                                            setUpdateGrid={setUpdateGrid} setScope={setScope2Data} ref={gridRef} handleTotal={handleTotal}
                                            type={1} loadSummaryData={loadSummaryData} baseYear={moment(baseYear).format('YYYY')} targetYear={moment(targetYear).format('YYYY')} />
                                    </div>
                                    <div className='line-div-5'></div>
                                </>
                            }


                            {
                                scopeCategory['scope3']?.length > 2 && <>
                                    <div className='mtop-4px'>
                                        <ScopeDataSheet scopeData={scope3Data} scope={scopeCategory['scope3']}
                                            setUpdateGrid={setUpdateGrid} setScope={setScope3Data} ref={gridRef} handleTotal={handleTotal}
                                            type={1} loadSummaryData={loadSummaryData} baseYear={moment(baseYear).format('YYYY')} targetYear={moment(targetYear).format('YYYY')} />
                                    </div>
                                    <div className='line-div-5'></div>
                                </>
                            }


                            <div className='mtop-4px'>
                                <ScopeDataSheet scopeData={offset} setUpdateGrid={setUpdateGrid}
                                    setScope={setOffset} ref={gridRef} type={3} />
                            </div>
                            <div className='line-div-5'></div>
                            <div className='mtop-4px'>
                                <ScopeDataSheet scopeData={summary}
                                    setScope={setSummary} ref={gridRef} type={4} />
                            </div>
                        </div>
                        {
                            sessionStorage.getItem('userType') === 'ASO_ADMIN' && <div className='mtop-1'>
                                <Button color='primary'
                                    className={`${(Number(calculatedTarget) > 0 && targetYear) ? '' : 'disabled-button'}`}
                                    onClick={onSubmit}
                                >Submit</Button>
                            </div>
                        }


                    </CardBody>
                </Card> : <NoDataComponent />

            }

        </Fragment>
    )
}

export default TargetSettingView;

const ScopeDataSheet = ({ scopeData, scope, setScope, ref, handleTotal, type, setUpdateGrid, baseYear, targetYear }) => {
    return (
        <DataSheet
            dataRef={ref}
            dataGrid={scopeData}
            onCellChanged={(changes) => {
                changes.forEach(({ cell, row, col, value }) => {
                    let newData = scopeData.map((row) => [...row]);
                    // setUpdateGrid(true)
                    if (type === 1) { // 1 for scope(1,2,3), 2 for biomass
                        setUpdateGrid(true)
                        if (isNumber(value)) {
                            newData[row][col] = {
                                ...scopeData[row][col],
                                // hiddenValue: scopeData[row][col]?.value,
                                actualData: false,
                                valueUpdated: true,
                            }
                            if (col === 1) {
                                newData[row].map((i, key) => {
                                    if (key > 0) {
                                        i.value = Number(value).toFixed(2)
                                        handleTotal(newData, key, scopeData, scope)
                                    }
                                })
                            } else {
                                newData[row].map((i, key) => {
                                    if (key > 0) {
                                        if (key >= col) { //update subsequent cells
                                            if ((Number(baseYear) + (key - 1)) > Number(targetYear)) {
                                                i.value = ''
                                                i.width = 100
                                            } else {
                                                i.value = 0
                                                i.value = Number(value).toFixed(2);
                                                i.valueUpdated = true;
                                                i.width = 100
                                            }

                                        }
                                        if (key < col && key !== 0) {
                                            if (i.value) {
                                                i.value = Number(i.value).toFixed(2)
                                                i.width = 100
                                            } else {
                                                i.value = 0
                                                i.width = 100
                                            }

                                        }
                                        handleTotal(newData, key, scopeData, scope)
                                    }
                                })
                            }
                            const calcData = handleTotal(newData, col, scopeData, scope)
                            setScope(calcData)
                        }
                    } else if (type === 3) {
                        setUpdateGrid(true)
                        let total = 0;
                        if (col === 0) {
                            newData[row][col] = {
                                ...scopeData[row][col],
                                value: value
                            }
                        }
                        if (col > 0 && isNumber(value)) {
                            if (newData[row][0]?.value) {
                                newData[row][col] = {
                                    ...scopeData[row][col],
                                    hiddenValue: !scopeData[row][col]?.value ? Number(0).toFixed(2) : scopeData[row][col]?.value,
                                    value: Number(value).toFixed(2),
                                    actualData: false,
                                    valueUpdated: true
                                }
                                for (let i = 1; i < scopeData.length - 1; i++) {
                                    total = total + Number(newData[i][col]?.value)
                                }
                                newData[scopeData?.length - 1][col] = {
                                    ...scopeData[scopeData?.length - 1][col], value: Number(total).toFixed(2),
                                    className: 'text-right-imp'
                                }
                            } else {
                                toast.info("Please Add Description for Offset")
                            }
                        }
                        setScope(newData)
                    }
                })
            }}
        />
    )
}

